<script>
  import {h, resolveComponent, isVNode} from 'vue';

  export default {
    name: "AppLayout",
    props: {
      layout: {
        type: String,
        default: 'quick-layout',
      },
      lay_empty: {
        type: String
      }
    },
    mounted() {

    },
    render() {

      let layoutName = this.layout;
      if(!layoutName){
        layoutName = window?.config?.layout;
      }
      if (this.lay_empty || !layoutName) {
        layoutName = 'router-view'
      }
      const layout = resolveComponent(layoutName);
      return h(layout)
    }
  }
</script>

